import React, { useState } from "react"
import { useStaticQuery, graphql } from "gatsby"
import Layout from "../components/layout"
import SEO from "../components/seo"
import "mapbox-gl/dist/mapbox-gl.css"
import ReactMapGL, { Marker } from "react-map-gl"
import { FaMapMarkerAlt } from "react-icons/fa"

function Map() {
  const [viewport, setViewport] = useState({
    width: "100%",
    height: 400,
    latitude: 47.51293358,
    longitude: -122.3535444,
    zoom: 16,
  })

  return (
    <ReactMapGL
      {...viewport}
      onViewportChange={setViewport}
      mapboxApiAccessToken="pk.eyJ1Ijoiam9lamVtbWVseSIsImEiOiJjazcxaXhqZmEwNmZkM2RvNWdmMmdsZ2ZiIn0.dxSzb_q7efiNtNreNm3KZg"
    >
      <Marker latitude={47.51293358} longitude={-122.3535444}>
        <a href="https://goo.gl/maps/G7DyTPpyEg1Y6Dia8">
          <FaMapMarkerAlt
            className="text-blue-500"
            size={40}
            style={{
              cursor: "pointer",
              transform: `translate(${-40 / 2}px,${-40}px)`,
            }}
          />
        </a>
      </Marker>
    </ReactMapGL>
  )
}

const ContactPage = () => {
  const data = useStaticQuery(graphql`
    query ContactPageQuery {
      site {
        siteMetadata {
          phone
        }
      }
    }
  `)
  return (
    <Layout>
      <SEO title="Contact" />
      <section className="py-12 md:py-24 bg-gray-100">
        <h1 className="text-center text-4xl sm:text-5xl font-semibold text-blue-500">
          Contact
        </h1>
      </section>
      <section>
        <Map />
      </section>
      <section className="bg-gray-50">
        <div className="max-w-screen-xl mx-auto py-12 px-4 sm:px-6 lg:py-16 lg:px-8">
          <h2 className="text-3xl text-center leading-9 font-medium text-gray-900 sm:text-4xl sm:leading-10">
            Walk-in Hours
          </h2>
          <div className="mt-6 border-t-2 border-gray-200 pt-6">
            <dl>
              <div className="md:grid md:grid-cols-12 md:gap-8">
                <dt className="text-base leading-6 font-medium text-gray-900 md:col-span-5">
                  Monday
                </dt>
                <dd className="mt-2 md:mt-0 md:col-span-7">
                  <p className="text-base leading-6 text-gray-500">
                    10 a.m. to 1:30 p.m. & 3 to 6 p.m.
                  </p>
                </dd>
              </div>
              <div className="mt-8 border-t border-gray-200 pt-6 md:grid md:grid-cols-12 md:gap-8">
                <dt className="text-base leading-6 font-medium text-gray-900 md:col-span-5">
                  Tuesday
                </dt>
                <dd className="mt-2 md:mt-0 md:col-span-7">
                  <p className="text-base leading-6 text-gray-500">
                    3 to 6 p.m.
                  </p>
                </dd>
              </div>
              <div className="mt-8 border-t border-gray-200 pt-6 md:grid md:grid-cols-12 md:gap-8">
                <dt className="text-base leading-6 font-medium text-gray-900 md:col-span-5">
                  Wednesday
                </dt>
                <dd className="mt-2 md:mt-0 md:col-span-7">
                  <p className="text-base leading-6 text-gray-500">
                    10 a.m. to 1:30 p.m. & 3 to 6 p.m.
                  </p>
                </dd>
              </div>
              <div className="mt-8 border-t border-gray-200 pt-6 md:grid md:grid-cols-12 md:gap-8">
                <dt className="text-base leading-6 font-medium text-gray-900 md:col-span-5">
                  Thursday
                </dt>
                <dd className="mt-2 md:mt-0 md:col-span-7">
                  <p className="text-base leading-6 text-gray-500">
                    3 to 6 p.m.
                  </p>
                </dd>
              </div>
              <div className="mt-8 border-t border-gray-200 pt-6 md:grid md:grid-cols-12 md:gap-8">
                <dt className="text-base leading-6 font-medium text-gray-900 md:col-span-5">
                  Friday
                </dt>
                <dd className="mt-2 md:mt-0 md:col-span-7">
                  <p className="text-base leading-6 text-gray-500">
                    10 a.m. to 2 p.m.
                  </p>
                </dd>
              </div>
              <div className="mt-8 border-t border-gray-200 pt-6 md:grid md:grid-cols-12 md:gap-8">
                <dt className="text-base leading-6 font-medium text-gray-900 md:col-span-5">
                  Saturday
                </dt>
                <dd className="mt-2 md:mt-0 md:col-span-7">
                  <p className="text-base leading-6 text-gray-500">
                    By appointment
                  </p>
                </dd>
              </div>
              <div className="mt-8 border-t border-gray-200 pt-6 md:grid md:grid-cols-12 md:gap-8">
                <dt className="text-base leading-6 font-medium text-gray-900 md:col-span-5">
                  Sunday
                </dt>
                <dd className="mt-2 md:mt-0 md:col-span-7">
                  <p className="text-base leading-6 text-gray-500">
                    Urgent appointments only
                  </p>
                </dd>
              </div>
            </dl>
            <div className="mt-8 border-t border-gray-200 pt-6">
              <p className="mb-4 text-base leading-6 text-gray-500 text-center">
                Please call for appointments outside of the walk-in hours.
              </p>
              <p className="text-base leading-6 text-gray-500 text-center">
                Our office is closed the following Holidays: <br /> Christmas,
                New Year, Memorial Day, Independence Day, Labor Day &
                Thanksgiving
              </p>
            </div>
          </div>
        </div>
      </section>
      <section className="bg-white py-16 px-4 overflow-hidden sm:px-6 lg:px-8 lg:py-24">
        <div className="relative max-w-xl mx-auto">
          <svg
            className="absolute left-full transform translate-x-1/2"
            width="404"
            height="404"
            fill="none"
            viewBox="0 0 404 404"
          >
            <defs>
              <pattern
                id="85737c0e-0916-41d7-917f-596dc7edfa27"
                x="0"
                y="0"
                width="20"
                height="20"
                patternUnits="userSpaceOnUse"
              >
                <rect
                  x="0"
                  y="0"
                  width="4"
                  height="4"
                  className="text-gray-200"
                  fill="currentColor"
                />
              </pattern>
            </defs>
            <rect
              width="404"
              height="404"
              fill="url(#85737c0e-0916-41d7-917f-596dc7edfa27)"
            />
          </svg>
          <svg
            className="absolute right-full bottom-0 transform -translate-x-1/2"
            width="404"
            height="404"
            fill="none"
            viewBox="0 0 404 404"
          >
            <defs>
              <pattern
                id="85737c0e-0916-41d7-917f-596dc7edfa27"
                x="0"
                y="0"
                width="20"
                height="20"
                patternUnits="userSpaceOnUse"
              >
                <rect
                  x="0"
                  y="0"
                  width="4"
                  height="4"
                  className="text-gray-200"
                  fill="currentColor"
                />
              </pattern>
            </defs>
            <rect
              width="404"
              height="404"
              fill="url(#85737c0e-0916-41d7-917f-596dc7edfa27)"
            />
          </svg>
          <div className="text-center">
            <h2
              id="contact-form"
              className="text-3xl leading-9 font-medium tracking-tight text-gray-900 sm:text-4xl sm:leading-10"
            >
              Contact Form
            </h2>
          </div>
          <div className="mt-12">
            <form
              netlify="true"
              name="contact"
              method="POST"
              data-netlify="true"
              action="/thank-you"
              className="grid grid-cols-1 row-gap-6 sm:grid-cols-2 sm:col-gap-8"
            >
              <input type="hidden" name="contact" value="contact" />
              <div>
                <label
                  for="first_name"
                  className="block text-sm font-medium leading-5 text-gray-700"
                >
                  First name
                  <div className="mt-1 relative rounded-md shadow-sm">
                    <input
                      id="first_name"
                      name="First name"
                      className="form-input py-3 px-4 block w-full transition ease-in-out duration-150"
                    />
                  </div>
                </label>
              </div>
              <div>
                <label
                  for="last_name"
                  className="block text-sm font-medium leading-5 text-gray-700"
                >
                  Last name
                  <div className="mt-1 relative rounded-md shadow-sm">
                    <input
                      id="last_name"
                      name="Last name"
                      className="form-input py-3 px-4 block w-full transition ease-in-out duration-150"
                    />
                  </div>
                </label>
              </div>
              <div className="sm:col-span-2">
                <label
                  for="email"
                  className="block text-sm font-medium leading-5 text-gray-700"
                >
                  Email
                  <div className="mt-1 relative rounded-md shadow-sm">
                    <input
                      id="email"
                      name="Email"
                      type="email"
                      className="form-input py-3 px-4 block w-full transition ease-in-out duration-150"
                    />
                  </div>
                </label>
              </div>
              <div className="sm:col-span-2">
                <label
                  for="phone_number"
                  className="block text-sm font-medium leading-5 text-gray-700"
                >
                  Phone Number
                  <div className="mt-1 relative rounded-md shadow-sm">
                    <input
                      id="phone_number"
                      name="Phone number"
                      className="form-input py-3 px-4 block w-full transition ease-in-out duration-150"
                      placeholder="(206) 763-7464"
                    />
                  </div>
                </label>
              </div>
              <div className="sm:col-span-2">
                <label
                  for="message"
                  className="block text-sm font-medium leading-5 text-gray-700"
                >
                  Message
                  <div className="mt-1 relative rounded-md shadow-sm">
                    <textarea
                      id="message"
                      name="Message"
                      rows="4"
                      className="form-textarea py-3 px-4 block w-full transition ease-in-out duration-150"
                    ></textarea>
                  </div>
                </label>
              </div>
              <div className="sm:col-span-2">
                <span className="w-full inline-flex rounded-md shadow-sm">
                  <button
                    type="submit"
                    className="w-full inline-flex items-center justify-center px-6 py-3 border border-transparent text-base leading-6 font-medium rounded-md text-white bg-blue-500 hover:bg-blue-400 focus:outline-none focus:border-blue-700 focus:shadow-outline-blue active:bg-blue-700 transition ease-in-out duration-150"
                  >
                    Submit
                  </button>
                </span>
              </div>
            </form>
          </div>
        </div>
      </section>
    </Layout>
  )
}

export default ContactPage
